<template>
  <v-container fluid>
    <v-form
      ref="order-form"
      @submit.prevent="onSubmit()">
      <v-row class="container-bg rounded elevation-3 mb-2">
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between align-center">
          <h2 v-if="isEdit">
            {{ `จัดการใบประกอบสินค้า - ${formData.documentno}` }}
          </h2>
          <h2 v-else>
            จัดการใบประกอบสินค้า
          </h2>
          <div class="d-flex flex-row align-center">
            <v-btn
              v-if="enableApprove"
              color="warning"
              :disabled="loading || !isEdit"
              @click="onConfirm()">
              อนุมัติ
            </v-btn>
            <v-btn
              class="mx-2"
              color="info"
              :disabled="loading || !isEdit || !formData.deliveryNoteId"
              @click="showDeliveryNote()">
              ดูใบนำเข้า
            </v-btn>
            <template v-if="formData.state === 'pending'">
              <v-btn
                class="px-8"
                color="success"
                :disabled="loading || formData.state === 'approved'"
                @click="onSubmit()">
                อัพเดท
              </v-btn>
              <v-btn
                class="ml-2"
                color="error"
                :disabled="loading || formData.state === 'approved'"
                @click="onConfirm(false)">
                ยกเลิก
              </v-btn>
            </template>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-for="(product, i) of formData.assemblyProducts"
        :key="`product-${i}`"
        class="container-bg rounded elevation-3">
        <v-col
          cols="12"
          class="header-line mt-4">
          <span>
            ข้อมูลสินค้า
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <img
            v-if="product.imageURL"
            :src="product.imageURL"
            class="elevation-1 main-image"
            alt="product-image">
          <img
            v-else
            :src="noImgAvailable"
            class="elevation-1 main-image"
            alt="product-image">
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span>
            {{ product.description }}
          </span>
          <v-simple-table>
            <thead>
              <tr>
                <th>
                  Code
                </th>
                <th class="text-center">
                  Color
                </th>
                <th class="text-center">
                  Size
                </th>
                <th>
                  Qty
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sku, skuIndex) in product.childsItemNo"
                :key="`product-${i}-sku-${skuIndex}`">
                <td>
                  {{ sku.itemNo }}
                </td>
                <td class="text-uppercase text-center">
                  {{ product.color }}
                </td>
                <td class="text-uppercase text-center">
                  {{ sku.size }}
                </td>
                <td>
                  {{ sku.quantity | showNumberFormat() }}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h3>
                    รวม
                  </h3>
                </td>
                <td>
                  <h3>
                    {{ getTotalQty(product) | showNumberFormat() }}
                  </h3>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          class="header-line mt-2">
          <span>
            ข้อมูลวัตถุดิบ
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="2">
        </v-col>
        <v-col
          cols="12"
          md="10">
          <v-simple-table>
            <thead>
              <tr>
                <th>
                  Item No.
                </th>
                <th>
                  Material name
                </th>
                <th>
                  QTY Total
                </th>
                <th>
                  STOCK
                </th>
                <th>
                  UOMs
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in product.assemblyLines"
                :key="`product-${i}-assembly-${index}`">
                <td>
                  {{ item.lineItemNo || 'วัตถุดิบใหม่' }}
                </td>
                <td>
                  {{ item.lineDescription }}
                </td>
                <!-- <td v-if="formData.state === 'pending'">
                  <v-text-field
                    v-model.number="item.lineQuantity"
                    color="secondary"
                    type="number"
                    dense
                    outlined
                    hide-details
                    :rules="textBoxRules"
                    :disabled="loading || formData.state === 'approved'" />
                </td> -->
                <td v-if="item.lineQuantity === item.bomQuantity">
                  {{ item.lineQuantity | showNumberFormat() }}
                </td>
                <td
                  v-else
                  class="d-flex flex-row align-center"
                  style="gap: 2px;">
                  <span>
                    {{ item.lineQuantity | showNumberFormat() }}
                  </span>
                  <v-icon color="error">
                    mdi-transfer-right
                  </v-icon>
                  <span class="error--text font-weight-bold">
                    {{ item.bomQuantity | showNumberFormat() }}
                  </span>
                </td>
                <td>
                  Stock
                  <!-- {{ item.lineStockQuantity | showNumberFormat() }} -->
                </td>
                <td>
                  {{ item.lineUom }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <!-- <v-col
          v-if="formData.state === 'pending'"
          cols="12"
          class="d-flex justify-center align-center"
          style="gap: 8px;">
          <v-btn
            color="secondary"
            :disabled="loading || formData.state === 'approved'"
            @click="toggleItem('RM', i)">
            เพิ่มวัตถุดิบ
          </v-btn>
        </v-col> -->
      </v-row>
    </v-form>
    <item-list-modal
      v-model="itemDialog"
      :type="type"
      :selected-items="selectedItems"
      @select="selectedItem($event)"
      @close="toggleItem('RM')" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AssemblyOrdersProvider from '@/resources/AssemblyOrdersProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import ProductProvider from '@/resources/ProductProvider'
import UnitOfMeasuresProvider from '@/resources/UnitOfMeasuresProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import AssemblyBOMProvider from '@/resources/AssemblyBOMProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import NoImgAvailable from '@/assets/image/no_picture_available.png'
import { getRole } from '@/assets/js/Authentication'
import ItemListModal from '../components/ItemListModal.vue'

const AssemblyOrdersService = new AssemblyOrdersProvider()
const ProductAttributeService = new ProductAttributeProvider()
const ProductService = new ProductProvider()
const UnitOfMeasuresService = new UnitOfMeasuresProvider()
const InventoryService = new InventoryProvider()
const AssemblyBOMService = new AssemblyBOMProvider()

export default {
  components: { ItemListModal },
  data () {
    return {
      loading: false,
      isEdit: false,
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ],
      UOMs: [],
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: [],
        groupCategories: []
      },
      defaultAssemblyLine: {
        sequence: 10000,
        lineType: '',
        lineItemNo: '',
        lineDescription: '',
        lineLocation: '',
        lineQuantity: 0,
        lineUom: '',
        imageURL: ''
      },
      formData: {
        postingDate: '',
        deliveryNoteId: null,
        location: '',
        assemblyProducts: [
          {
            itemNo: '',
            childsItemNo: [],
            description: '',
            imageURL: '',
            quantity: 0,
            groupCategory: '',
            size: '',
            color: '',
            categories: '',
            gwCollection: '',
            model: '',
            brand: '',
            genBusPostingGroup: 'Assembly',
            noOfLines: 1,
            assemblyLines: []
          }
        ],
        state: 'pending'
      },
      lineTypes: [
        'Comment',
        'G/L Account',
        'Item',
        'Resource',
        'Fixed Asset',
        'Charge (Item)'
      ],
      itemDialog: false,
      itemSelectIndex: -1,
      type: 'FG',
      noImgAvailable: NoImgAvailable,
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      store: 'Store/store'
    }),
    warehouses () {
      return this.mapWarehouse.filter((w) => w.id !== 0).sort((a, b) => a.code.localeCompare(b.code))
    },
    enableApprove () {
      const role = getRole()
      const setCountRoles = [
        'management',
        'developer',
        'accounting_manager'
      ]

      return setCountRoles.some((r) => r === role) && this.formData.state === 'pending'
    },
    factoryWarehouse () {
      return this.store.name.toLowerCase() === 'gentlewoman' ? 'GW-FACTORY' : 'MM-FACTORY'
    }
  },
  async mounted () {
    await this.getAllAttributes()

    if (this.$route.params?.id) {
      this.isEdit = true
      this.getItem()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    toggleItem (type, index = -1) {
      this.type = type
      this.itemSelectIndex = index

      if (index === -1) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.formData.assemblyProducts[index].assemblyLines.map((v) => ({ ...v, itemNo: v.lineItemNo }))]
      }

      this.itemDialog = !this.itemDialog
    },
    removeItem (parent, index) {
      this.formData.assemblyProducts[parent].assemblyLines.splice(index, 1)
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    async getAllAttributes () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await ProductAttributeService.getAllProductAttribute()

        this.productAttributes = {
          colors: Array.isArray(data.colors) ? this.getColor(data.colors) : [],
          brands: Array.isArray(data.brands) ? data.brands : [],
          size: Array.isArray(data.size) ? data.size : [],
          tags: Array.isArray(data.tags) ? data.tags : [],
          categories: Array.isArray(data.categories) ? data.categories : [],
          collections: Array.isArray(data.collections) ? [{ id: null, name: 'ไม่ระบุ' }, ...data.collections] : [],
          groupCategories: Array.isArray(data.groupCategories) ? data.groupCategories : []
        }

        const { data: uom } = await UnitOfMeasuresService.getItems({
          page: 1,
          limit: 9999
        })

        this.UOMs = uom.results.map((r) => ({
          text: r.name,
          value: r.code
        }))
      } catch (error) {
        console.error('getAllAttributes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async getItem () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await AssemblyOrdersService.getItemById(this.$route.params.id)
        const { data: boms } = await AssemblyBOMService.getItemsByParent(data.assemblyProducts.map((p) => p.itemNo))

        this.formData = {
          ...data,
          assemblyProducts: data.assemblyProducts.map((p) => {
            const bom = boms.find((b) => b.parentItemNo === p.itemNo)

            if (!bom || data.state !== 'pending') {
              return {
                ...p,
                assemblyLines: p.assemblyLines.map((v) => ({
                  ...v,
                  bomQuantity: v.lineQuantity
                }))
              }
            }

            const assemblyLines = bom.assemblyBOMLines.map((bl, i) => {
              const orderAssemblyLine = p.assemblyLines.find((a) => a.lineItemNo === bl.itemNo)

              return {
                sequence: (i + 1) * 10000,
                lineType: bl.lineType,
                lineItemNo: bl.itemNo,
                lineDescription: bl.description,
                lineLocation: this.factoryWarehouse,
                lineQuantity: orderAssemblyLine?.lineQuantity ?? bl.quantityPer * this.getTotalQty(p),
                bomQuantity: bl.quantityPer * this.getTotalQty(p),
                lineUom: bl.uom,
                imageURL: bl.imageURL
              }
            })

            return {
              ...p,
              assemblyLines
            }
          })
        }
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    onConfirm (isApprove = true) {
      const isBomUpdate = this.formData.assemblyProducts.some((p) => p.assemblyLines.some((l) => l.lineQuantity !== l.bomQuantity))

      if (isApprove) {
        this.setModal({
          value: true,
          title: 'Approve',
          message: isBomUpdate
            ? 'BOM was updated Do you want to approve this assembly order with current quantity'
            : 'Do you want to approve this assembly order',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onApprove()
        })
      } else {
        this.setModal({
          value: true,
          title: 'Cancel',
          message: 'Do you want to cancel this assembly order',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onCancel()
        })
      }
    },
    async showDeliveryNote () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await InventoryService.getDeliveryNoteByCode(this.formData.deliveryNoteId)

        this.$router.push({
          name: 'DeliveryNote',
          query: {
            id: data.id,
            warehouse: data.warehouse.id
          }
        })
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onApprove () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        await AssemblyOrdersService.approveItem(this.formData.id)
        this.setSnackbar({
          value: true,
          message: 'แก้ไขใบสั่งผลิตสำเร็จ',
          type: 'success'
        })

        this.getItem()
      } catch (error) {
        console.error('onApprove', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onCancel () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        await AssemblyOrdersService.cancelItem(this.formData.id)
        this.setSnackbar({
          value: true,
          message: 'ยกเลิกใบสั่งผลิตสำเร็จ',
          type: 'success'
        })

        this.getItem()
      } catch (error) {
        console.error('onCancel', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onSubmit () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        const assemblyProducts = this.formData.assemblyProducts.map((p) => ({
          ...p,
          assemblyLines: p.assemblyLines.map((asm, index) => ({
            ...asm,
            sequence: (index + 1) * 10000,
            lineLocation: this.factoryWarehouse,
            lineQuantity: asm.bomQuantity
          })),
          noOfLines: p.assemblyLines.length
        }))

        if (this.isEdit) {
          await AssemblyOrdersService.updateItem({
            ...this.formData,
            assemblyProducts
          })

          this.setSnackbar({
            value: true,
            message: 'แก้ไขใบสั่งผลิตสำเร็จ',
            type: 'success'
          })

          this.getItem()
        } else {
          await AssemblyOrdersService.createItem({
            ...this.formData,
            assemblyProducts
          })

          this.setSnackbar({
            value: true,
            message: 'สร้างใบสั่งผลิตสำเร็จ',
            type: 'success'
          })

          this.$router.push({ name: 'PurchasesAssemblyOrders' })
        }
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async getProductByCodes (code) {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await ProductService.getManyProductByCode({ codes: [code] })

        this.setLoading({ active: false })
        this.loading = false

        return data
      } catch (error) {
        console.error('getProductByCodes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })

        return []
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async selectedItem (item) {
      if (this.type === 'FG') {
        this.formData.assemblyProducts.push({
          itemNo: item.code,
          imageURL: item.imageURL,
          description: item.name,
          groupCategory: item.groupCategories[0],
          size: item.size.name,
          color: item.color.name,
          categories: item.categories[0],
          gwCollection: item.gwCollection,
          model: item.model,
          brand: item.brand,
          assemblyLines: []
        })

        this.toggleItem('RM')
      } else if (this.type === 'RM') {
        this.formData.assemblyProducts[this.itemSelectIndex].assemblyLines.push({
          ...this.defaultAssemblyLine,
          sequence: this.formData.assemblyProducts[this.itemSelectIndex].assemblyLines.length + 10000,
          lineType: 'Item',
          lineItemNo: item.itemNo,
          lineDescription: item.description,
          lineUom: item.baseUOM || item.salesUOM || item.purchaseUOM || '',
          imageURL: item.imageURL
        })
      } else if (this.type === 'BOM') {
        const products = await this.getProductByCodes(item.parentItemNo)
        const product = products[0] || null
        const sku = product?.skus?.find((s) => s.code === item.parentItemNo) || null

        this.formData = {
          ...this.formData,
          itemNo: item.parentItemNo,
          imageURL: item.imageURL,
          description: item.description,
          groupCategory: product?.groupCategories[0] || '',
          size: sku?.size?.name || '',
          color: sku?.color?.name || '',
          categories: product?.categories[0] || '',
          gwCollection: product?.gwCollection?.nickname || '',
          model: product?.model || '',
          brand: item.brand,
          assemblyLines: item.assemblyBOMLines.map((line, index) => ({
            sequence: (index + 1) * 10000,
            lineType: 'Item',
            lineItemNo: line.itemNo,
            lineDescription: item.description,
            lineLocation: this.factoryWarehouse,
            lineQuantity: line.quantityPer,
            lineUom: line.uom,
            imageURL: line.imageURL
          }))
        }

        this.toggleItem('RM')
      }
    },
    getTotalQty (product) {
      return product.childsItemNo.reduce((sum, sku) => sum + sku.quantity, 0)
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
